import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { BlogComponent } from './pages/blog/blog.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module')
          .then(m => m.HomeModule),
      },
      {
        path: 'product',
        loadChildren: () => import('./pages/product/product.module')
          .then(m => m.ProductModule),
      },
      {
        path: 'order',
        loadChildren: () => import('./pages/order/order.module')
          .then(m => m.OrderModule),
      },
      {
        path: 'cart',
        loadChildren: () => import('./pages/cart/cart.module')
          .then(m => m.CartModule),
      },
      {
        path: 'profiles',
        loadChildren: () => import('./pages/profiles/profiles.module')
          .then(m => m.ProfilesModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module')
          .then(m => m.SettingModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module')
          .then(m => m.AccountModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('./pages/legal/legal.module')
          .then(m => m.LegalModule),
      },
      {
        path: 'blog',
        component: BlogComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'contactus',
        component: ContactusComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      //{
      //  path: '**',
      //  component: MainComponent,
      //},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
