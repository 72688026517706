import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShopifyServiceService } from '../service/shopify-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  storeName: any;
  year = 0;

  constructor(
    private router: Router,
    private shopifyService: ShopifyServiceService
  ) { }


  ngOnInit(): void {
    if (localStorage.getItem('FFS_Shop')) {
      const response = JSON.parse(localStorage.getItem('FFS_Shop'));
      this.storeName = response.data.shop.name;
    } else {
      console.warn('Footer ***********************');
      this.shopifyService.getShopData().subscribe(
        (response) => {
          localStorage.setItem('FFS_Shop', JSON.stringify(response));
          this.storeName = response.data.shop.name;
        },
        (error) => {
          console.error('Error fetching store info:', error);
        });
    }
    this.year = new Date().getFullYear();
  }

  // ngOnInit(): void {
  //   if (localStorage.getItem('FFS_Shop')) {
  //     this.storeInfo = JSON.parse(localStorage.getItem('FFS_Shop'));
  //   } else {
  //     console.warn('Footer ***********************');
  //     this.storeInfo = this.shopifyService.getShopData();
  //     this.storeInfo.subscribe(
  //       (response) => {
  //         localStorage.setItem('FFS_Shop', JSON.stringify(response));
  //       },
  //       (error) => {
  //         console.error('Error fetching store info:', error);
  //       });
  //   }
  //   this.year = new Date().getFullYear();
  // }

  privacypolicy(){
    this.router.navigate(['/legal/privacypolicy']);
  }

  shipping(){
    this.router.navigate(['/legal/shippingpolicy']);
  }

  refund(){
    this.router.navigate(['/legal/returnpolicy']);
  }

  terms(){
    this.router.navigate(['/legal/termsofuse']);
  }

  about(){
    this.router.navigate(['/about']);
  }

  contact(){
    this.router.navigate(['/contactus']);
  }


}

