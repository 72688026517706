import { Component, OnInit,NgZone  } from '@angular/core';
import { Collection } from '../models/collection.model';
import { Product } from '../models/product.model';
import { ShopifyServiceService } from '../service/shopify-service.service';
import { GlobalService } from '../service/global.service';
import {slideToggle} from 'slidetoggle';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError  } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  countCart = 0;
  collections: Array<Collection>;
  public collectionsfinal: Array<Collection> = [];
  login = false;
  displayname = "";
  products: Array<Product>;
  prefixSearch = "";
  topfooter = "";

  constructor(private sanitizer:DomSanitizer,private globalService: GlobalService,private shopifyService: ShopifyServiceService,private ngZone:NgZone,private router: Router) { 
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // };

    // var collec = [];
    // this.shopifyService.getCollection().then((cls) => {
    //   this.collections = cls;
    //   this.collections.forEach(function (item) {
    //     if(item.products.length > 0){
    //       if(item.title != "Featured Products"){
    //         collec.push(item);
    //       }
    //     }
    //   });
    //   this.collectionsfinal = collec; 
    // }, err=> console.log(err));

    var that = this;
    if(localStorage.getItem('FFS_Collections')) {
      var data  = JSON.parse(localStorage.getItem('FFS_Collections'));
      that.LoadCollectionsData(data);
    }
    else{
      this.shopifyService.getCollection().then((page) => {
        localStorage.setItem('FFS_Collections', JSON.stringify(page));
        that.LoadCollectionsData(page);        
      }, err=> console.log(err));      
    }
  }

  LoadCollectionsData(cls){
    var collec = [];
    this.collections = cls;
      this.collections.forEach(function (item) {
        if(item.products.length > 0){
          if(item.title == "Featured Products" || item.title == "Home Top"){
            //ignore this category
          }
          else{
            collec.push(item);
          }
          
        }
      });
      this.collectionsfinal = collec; 
  }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(   
      debounceTime(200), 
      distinctUntilChanged(),
      // switchMap allows returning an observable rather than maps array
      switchMap( (searchText) =>  this.shopifyService.getProductsSearch(searchText))
    );        
  }

  isObject(val) {
    return (typeof val === 'object');
  }

  selectedItem(selecteditem){
    if(selecteditem != null){
      this.prefixSearch = "";
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      var id = selecteditem.item.id.replace("gid://shopify/Product/","");
      this.router.navigate(['/product/detail/' + id]);
    }
  }

  mobile_toggle(){
    if ($('body').hasClass('openNav')) {
      $('body').removeClass('openNav');
    } else {
      $('body').addClass('openNav');
    }
  }

  subitem_toggle(){
    if (!$('.myaccounta').parent().hasClass('openSubMenu')) {
      $('.myaccounta').parent().addClass('openSubMenu');
      $('.myaccounta').parent().find('.subMenu').slideDown();
    } else {
      $('.myaccounta').parent().removeClass('openSubMenu');
      $('.myaccounta').parent().find('.subMenu').slideUp();
    }
  }

  mobileSearchBtn(){
    $(".searchBlock").slideToggle();
    $(".mainWrapper").toggleClass('serchBoxBgLayer');
  }

  resultFormat(value: any) {            
    return value.title;
  } 
  inputFormat(value: any)   {
    if(value.title)
      return value.title
    return value;
  }

  onClickedOutside() {
    this.hide_list();
  }

  menu_toggle(){
    setTimeout(function(){ 
      $(".linkDropDown").slideToggle()
    }, 500);
    
    //slideToggle.slideToggle('.main-navigation', 300);
    
  }

  hide_resourcse(){
    if ($(".linkDropDown").is(":visible")) {
      slideToggle.slideToggle('.linkDropDown');
    }
  }

  hide_list(){
    this.hide_resourcse();
    return false;
  }

  productlist(id){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    id = id.replace("gid://shopify/Collection/","");
    this.router.navigate(['/product/list/' + id]);
  }

  contact(){
    this.router.navigate(['/contactus']);
  }
  
  homeurl(){
    this.router.navigate(['/home']);
  }

  urllink(obj){
    if(obj == 1){
      this.router.navigate(['/account/signin']);
    }
    else if(obj == 2){
      this.router.navigate(['/account/signup']);
    }
    else if(obj == 3){
      this.router.navigate(['/profiles/detail']);
    }
    else if(obj == 4){
      this.router.navigate(['/order/list']);
    }
  }

  carturl(){
    this.router.navigate(['/cart/item']);
  }

  LoadFooterData(page){
    var that = this;
     var pages = [];
      pages = page.data.pages.edges;
      pages.forEach(function (item) {
        if(item.node.title == "Announcement Bar"){
          that.topfooter = item.node.body;
        } 
      });
  }

  ngOnInit(): void {
    if(localStorage.getItem('FFSToken')) {
      this.displayname = localStorage.getItem('FFS_displayname');
      this.login = true;
    }
    else{
      this.login = false;
    }

    var that = this;
    if(localStorage.getItem('FFS_Pages')) {
      var data  = JSON.parse(localStorage.getItem('FFS_Pages'));
      that.LoadFooterData(data);
    }
    else{
      this.shopifyService.getPages().then((page) => {
        localStorage.setItem('FFS_Pages', JSON.stringify(page));
        that.LoadFooterData(page);        
      }, err=> console.log(err));      
    }
    // this.shopifyService.getPages().then((page) => {
    //   var pages = [];
    //   pages = page.data.pages.edges;
    //   pages.forEach(function (item) {
    //     if(item.node.title == "Top Footer"){
    //         that.topfooter = item.node.body;
    //     } 
    //   });
    // }, err=> console.log(err));

    
    setInterval(() => {
      var count = this.globalService.cartCount.length;
      this.countCart = count;
      //Passing the false flag would prevent page reset to 1 and hinder user interaction
    }, 100);  
  }

}
