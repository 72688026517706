import { Injectable } from '@angular/core';
import { Cart } from '../models/cart.model';
import { LineItem } from '../models/lineItem.model';
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })

export class GlobalService {

    cartObs: BehaviorSubject<Cart> = new BehaviorSubject(new Cart);
    lineItemsObs: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
    newlineItemObs: BehaviorSubject<LineItem> = new BehaviorSubject(null);
    cartOpenCloseObs: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor() {
        let cart = new Cart;
        this.cartObs.next(cart);
    }

    get cart() {
        return this.cartObs.getValue();
    }

    get cartCount() {
        return this.lineItemsObs.getValue();
    }

    set cart(cart) {
        this.cartObs.next(cart);
    }

    get cartOpenClose(){
        return this.cartOpenCloseObs.getValue();
    }

    set cartOpenClose(value: boolean){
        this.cartOpenCloseObs.next(value);
    }

    addItemToCart(lineItem: LineItem) {
        let lineItems = this.lineItemsObs.getValue();
        
        let sameVariant = lineItems.filter(item => item.variantId == lineItem.variantId);
        
        if (sameVariant.length > 0) {
            sameVariant[0].quantity = sameVariant[0].quantity + lineItem.quantity;
            this.lineItemsObs.next(lineItems);
        } else {
            this.lineItemsObs.next([...this.lineItemsObs.getValue(), lineItem])
            //this.newlineItemObs.next(lineItem);          
        }
        
        if (localStorage.getItem('FFS_Cart') != null) {
            localStorage.removeItem("FFS_Cart");
        }
        let lineItemsNew = this.lineItemsObs.getValue();
        if(lineItemsNew.length > 0){
            var str = JSON.stringify(lineItemsNew);
            localStorage.setItem('FFS_Cart', str);
        }
    }


    removeItem(lineItem) {
       // let lineItems = this.lineItemsObs.getValue();
        let lineItems = this.lineItemsObs.value;
        let index = lineItems.indexOf(lineItem);
        if (index!=-1) {
            lineItems.splice(index, 1)
            this.lineItemsObs.next(lineItems);
            if (localStorage.getItem('FFS_Cart') != null) {
                localStorage.removeItem("FFS_Cart");
            }
            let lineItemsNew = this.lineItemsObs.getValue();
            if(lineItemsNew.length > 0){
                var str = JSON.stringify(lineItemsNew);
                localStorage.setItem('FFS_Cart', str);
            }
        }
    }

    removeItemAll() {
        // let lineItems = this.lineItemsObs.getValue();
         this.lineItemsObs.next([]);
         this.newlineItemObs.next(null);
         localStorage.removeItem("FFS_Cart");
     }
}


