import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { NgbModal,NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }
  modalOption: NgbModalOptions = {}; 
  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {

    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.size = dialogSize;   
    const modalRef = this.modalService.open(ConfirmationDialogComponent,this.modalOption);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}
