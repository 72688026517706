<header class="header">
	<div class="topBg">
		<div class="container" [innerHTML]="transform(topfooter)">
			<!-- We Ship Worldwide! We Customize It ALL! -->
		</div>
	</div>
	<div class="container">
		<div class="headerLogoRow">
			<div class="row align-items-center">
				<div class="col-4 col-lg-4">
					<div class="searchCol">
						<div class="searchBlock">
							<input class="input" placeholder="Search" type="text" [(ngModel)]="prefixSearch" (selectItem)="selectedItem($event)" name="searchtop" [ngbTypeahead]="search" [inputFormatter]="inputFormat" [resultFormatter]="resultFormat" #instance="ngbTypeahead">
							<span class="searcMobileClose"></span>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="logo"><a href="javascript:void(0)" (click)="homeurl()"><img src="assets/images/logo.png" alt=""></a></div>
				</div>
				<div class="col-4 col-lg-4">
					<div class="navRight">
						<button class="mobileSearchBtn" (click)="mobileSearchBtn()"></button>
						<div class="loginLink" >
							<a href="javascript:void(0)" (click)="urllink(1)">
								<span class="text">{{displayname}}</span>
							</a>
							
							<!-- <div class="linkDropDown">
								<ul >
									<li></li>
									<li><a href="javascript:void(0)" (click)="urllink(3)">Profile</a></li>
									<li><a href="javascript:void(0)" (click)="urllink(4)">Orders</a></li>
								</ul>
							</div> -->
						</div>
						<div class="cartLink"><a href="javascript:void(0)" (click)="carturl()"><span class="count" id="productcartitem">{{countCart}}</span><span class="icon"></span></a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="navigatinBg">
		<div class="container">
			<div class="mainNav">
				<button class="mobileNavBtn" (click)="mobile_toggle()">
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div class="overLay" (click)="mobile_toggle()"></div>
				<nav class="navigation">
					<ul>
						<li><a href="javascript:void(0)" (click)="homeurl()">home</a></li>
						<li *ngFor="let collection of collectionsfinal"><a href="javascript:void(0)" (click)="productlist(collection.id)">{{ collection.title }}</a></li>
						<li><a href="javascript:void(0)" (click)="contact()">Contact</a></li>
						<li class="mobileShow hasChild">
							<a href="javascript:void(0)" (click)="subitem_toggle()" class="myaccounta">My Account</a>
							<ul class="subMenu" >
								<li>{{displayname}}</li>
								<li><a href="javascript:void(0)" (click)="urllink(3)">Profile</a></li>
								<li><a href="javascript:void(0)" (click)="urllink(4)">Orders</a></li>
							</ul>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</header>
<script>
	$(document).ready(function(){
		$(".myaccounta").click(function(e){
			e.preventDefault();
			if (!$(this).parent().hasClass('openSubMenu')) {
				$(this).parent().addClass('openSubMenu');
				$(this).parent().find('.subMenu').slideDown();
			} else {
				$(this).parent().removeClass('openSubMenu');
				$(this).parent().find('.subMenu').slideUp();
			}
		});

		$(".loginLinkA").click(function(e){
			if ($(this).next('.linkDropDown').is(':visible')) {
				$(this).next('.linkDropDown').slideUp();
			} else {
				$(this).next('.linkDropDown').slideDown();
			}
		});
	});
	
</script>