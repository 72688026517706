
import { NgModule,APP_INITIALIZER  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuard } from '../guards/auth-guard.service';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { LoaderService } from './loader.service';
import { ShopifyServiceService } from './shopify-service.service';
import { GlobalService } from './global.service';
import { ProductService } from './product.service';
import { LocaldataService } from './localdata.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    AuthGuard,
    ConfirmationDialogService,
    LoaderService,
    ShopifyServiceService,
    GlobalService,
    ProductService,
    LocaldataService
  ]
})

export class ServiceModule {
}
