import { Injectable } from '@angular/core';
import { ShopifyServiceService } from './shopify-service.service';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class LocaldataService {
  Pages = {};
  constructor(private storage: StorageMap,private shopifyService: ShopifyServiceService) {

  }

  GetPageData():any {
    var that = this;
   return this.storage.get('Pages').subscribe((data) => {    
      if(data == null || data == undefined){
        return this.shopifyService.getPages().then((page) => {
           this.storage.set('Pages', page).subscribe(() => {});
           return page;         
         }, err=> console.log(err));  
      }
      else{
        return data;
      }
    });
   }
  

  SetPageData():any{

  }
}
