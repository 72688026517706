<section class="newsLetterSec">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="textA" style="text-transform: unset;font-size: 16px;" [innerHTML]="transform(htmlbody)"></div>
                <!-- <h2 class="textB" [innerHTML]="transform(htmlbody)"></h2> -->
            </div>
           
        </div>
    </div>
</section>
<!-- <section class="newsLetterSec">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="textA">BECOME A MEMBER OF THE FAMILY</div>
                <h2 class="textB">Sign Up For Lates News and Updates</h2>
            </div>
            <div class="col-md-5">
                <div class="subscribeBox">
                    <div class="subscribeRow">
                        <input class="input" placeholder="Your email address" type="text" name="">
                        <button type="button" class="submitBtn">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->