import { Component, OnInit } from '@angular/core';
import { ShopifyServiceService } from '../service/shopify-service.service';
import { LocaldataService } from '../service/localdata.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.css']
})
export class NewsLetterComponent implements OnInit {

  htmlbody = "";
  constructor(private storage: StorageMap,private localService: LocaldataService,private shopifyService: ShopifyServiceService,private sanitizer:DomSanitizer) { }

  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  LoadData(page){
    var that = this;
     var pages = [];
      pages = page.data.pages.edges;
      pages.forEach(function (item) {
        if(item.node.title == "Blue Footer"){
          that.htmlbody = item.node.body;
        } 
      });
  }

  ngOnInit(): void {
    var that = this;
    if(localStorage.getItem('FFS_Pages')) {
      var data  = JSON.parse(localStorage.getItem('FFS_Pages'));
      that.LoadData(data);
    }
    else{
      this.shopifyService.getPages().then((page) => {
        localStorage.setItem('FFS_Pages', JSON.stringify(page));
        that.LoadData(page);        
      }, err=> console.log(err));      
    }
  }

}
