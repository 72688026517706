import { Component, OnInit } from '@angular/core';
import { LineItem } from './models/lineItem.model';
import { ShopifyServiceService } from './service/shopify-service.service';
import { GlobalService } from './service/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
  title = 'FamilyFraternalSales';
  login = false;
  lineItems: LineItem[];

  constructor(private globalService: GlobalService,private shopifyService: ShopifyServiceService) { 
    // localStorage.removeItem("FFS_Shop");
    // localStorage.removeItem("FFS_Collections");
    // localStorage.removeItem("FFS_Pages");
    // localStorage.removeItem("FFS_FEATURED_PRODUCTS");
    // localStorage.removeItem("FFS_ProductsTopSix");
    // localStorage.removeItem("FFS_orders");
    // localStorage.removeItem("FFS_profile_address");
    // localStorage.removeItem("FFS_top_products");
  }

  call(){
    setInterval(function(){ 
      var getdate = localStorage.getItem('FFS_hr');
      var d = new Date();
      if(getdate != null){
        var today = new Date(getdate);
        if(d > today){
            localStorage.removeItem("FFS_Shop");
            localStorage.removeItem("FFS_Collections");
            localStorage.removeItem("FFS_Pages");
            localStorage.removeItem("FFS_FEATURED_PRODUCTS");
            localStorage.removeItem("FFS_ProductsTopSix");
            localStorage.removeItem("FFS_orders");
            localStorage.removeItem("FFS_profile_address");
            localStorage.removeItem("FFS_top_products");
            localStorage.setItem('FFS_Refresh', "Yes");
            localStorage.setItem('FFS_hr', null);
        }
      }
    }, 5000);
    
    // else{

    //   // localStorage.removeItem("FFS_Shop");
    //   // localStorage.removeItem("FFS_Collections");
    //   // localStorage.removeItem("FFS_Pages");
    //   // localStorage.removeItem("FFS_FEATURED_PRODUCTS");
    //   // localStorage.removeItem("FFS_orders");
    //   // localStorage.removeItem("FFS_profile_address");
    //   // localStorage.removeItem("FFS_top_products");

    // }
  }

  ngOnInit(): void {
    
    if(localStorage.getItem('FFSToken')) {
      this.login = true;
    }
    else{
      this.login = false;
    }
    if (localStorage.getItem('FFS_hr') == null || localStorage.getItem('FFS_hr') == 'null') {
      var today = new Date();
      today.setHours(today.getHours() + 3);
      localStorage.setItem('FFS_hr', today.toString());
      this.call();
      
    }
    else{
      this.call();
    }

    if (localStorage.getItem('FFS_Cart') == null) {
    }
    else{
      if (localStorage.getItem('ChackoutWeb') == null || localStorage.getItem('ChackoutWeb') == "0") {
        let cart: any = JSON.parse(localStorage.getItem('FFS_Cart'));
        this.lineItems = cart;
        if(this.lineItems != null){
          this.lineItems.forEach( (element) => {
            this.globalService.addItemToCart(element);
          });
        }
      }
      
      
    }
  }

}
