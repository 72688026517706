import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    
       let key = 'FFSToken';
       let myItem = localStorage.getItem(key);

      var auth =  req.headers.get("Authorization");
      if (auth == null || auth == "") {
        req= req.clone({headers: req.headers.append('Authorization', 'Bearer ' + myItem)});
      }
       
      
      return next.handle(req);

        // if (myItem) {
        //   req = req.clone({
        //         setHeaders: { 
        //             Authorization: `Bearer ${myItem}`
        //         }
        //     });
        // }

        // return next.handle(req);
  }
}