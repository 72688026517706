<div class="aboutUsPage">
		<div class="innerTopBanner">
			<img class="img" src="assets/images/about-us-banner.jpg" alt="">
		</div>
		<div class="container">
			<div class="privacyTextBox" [innerHTML]="transform(htmlbody)">
			</div>
		</div>
		<!-- <div class="container">
			<div class="aboutTopBoxRow">
				<div class="row">
					<div class="col-md-4">
						<div class="aboutBox">
							<span class="circle">1</span>
							<h3>Feature 1</h3>
							<div class="desc">
								vitae mollis purus lectus ut leo. Mauris vehicula augue neque, pretium mattis ligula sollicitudin vel. Nulla facilisi.
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="aboutBox">
							<span class="circle">2</span>
							<h3>Feature 2</h3>
							<div class="desc">
								vitae mollis purus lectus ut leo. Mauris vehicula augue neque, pretium mattis ligula sollicitudin vel. Nulla facilisi.
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="aboutBox">
							<span class="circle">3</span>
							<h3>Feature 3</h3>
							<div class="desc">
								vitae mollis purus lectus ut leo. Mauris vehicula augue neque, pretium mattis ligula sollicitudin vel. Nulla facilisi.
							</div>
						</div>
					</div>
				</div>
			</div>
			<section class="aboutPageSecB">
				<h2>Our Story</h2>
				<div class="desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sodales vel neque nec porta. Suspendisse semper, nunc suscipit vulputate cursus, dolor nisi volutpat felis, vitae mollis purus lectus ut leo. Mauris vehicula augue neque, pretium mattis ligula sollicitudin vel. Nulla facilisi. Proin pharetra sem neght mauris sodales vel neque nec porta. </div>
			</section>
			<section class="aboutPageSecC">
				<div class="row">
					<div class="col-md-4">
						<h3>What we stand for</h3>
						<div class="desc">Donec diam nibh, sodales id laoreet fermentum, porttitor sit amet est. Integer eget purus ac leo semper hendrerit ac id dui. Duis porttitor lacus eget enim dictum mollis.</div>
					</div>
					<div class="col-md-4">
						<h3>Our Mission</h3>
						<div class="desc">Donec diam nibh, sodales id laoreet fermentum, porttitor sit amet est. Integer eget purus ac leo semper hendrerit ac id dui. Duis porttitor lacus eget enim dictum mollis.</div>
					</div>
					<div class="col-md-4">
						<h3>Our Vision</h3>
						<div class="desc">Donec diam nibh, sodales id laoreet fermentum, porttitor sit amet est. Integer eget purus ac leo semper hendrerit ac id dui. Duis porttitor lacus eget enim dictum mollis.</div>
					</div>
				</div>
			</section>
		</div> -->
	</div>
	