import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate {

  userid = "";
  token = "";
  constructor(private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(window.localStorage.getItem('FFSToken')) {
      this.userid = window.localStorage.getItem('FFS_UserId');
      this.token = window.localStorage.getItem('FFSToken');
      // this.ProfileService.getValidation(this.userid).subscribe(
      //   dataValidation => {
      //     if(dataValidation == 0){
      //       this.ProfileService.getToken(this.userid).subscribe(
      //         data => {
      //           if(data.State == "1"){
      //             let tokendb = data.token;
      //             if(this.token != tokendb){
      //               localStorage.setItem("BlueportalToken", tokendb);
      //             }
      //             return true;
      //           }
      //           else{
      //             this._router.navigate(['/account/login']);
      //             return false;
      //           }
      //       });
      //     }
      //   });
      
      return true;
    }
    

    // navigate to login page
    this._router.navigate(['/account/signin']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }

}