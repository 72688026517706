import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { Address } from '../models/address.model';
import { Collection } from '../models/collection.model';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {Observable} from 'rxjs';
import * as $ from 'jquery';

import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import ApolloClient from 'apollo-boost';
import GraphQLJSClient from 'graphql-js-client';
import typeBundle from './types';

const GET_SHOP = gql`
  {
    shop {
      name
      privacyPolicy {
        body
        title
      }
      termsOfService {
        body
        title
      }
      refundPolicy {
        body
        title
      }
    }
  }
`;


@Injectable({
  providedIn: 'root'
})
export class ShopifyServiceService {

constructor(private http: HttpClient, private apollo: Apollo) { }

client = new GraphQLJSClient(typeBundle, {
    url: environment.url,
    fetcherOptions: {headers: {'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken}}
});

  // Create the Apollo Client instance with custom headers
  apolloClient = new ApolloClient({
  uri: environment.url, // Replace with your GraphQL endpoint
  request: (operation) => {
    operation.setContext({
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
      }
    });
  }
});

getShopData(): Observable<ApolloQueryResult<{ shop: { name: string } }>> {
    return this.apollo.query<{ shop: { name: string } }>({
      query: GET_SHOP,
    });
  }

getProductById(_id): Promise<any> {

  return  this.apolloClient.query({
      query: gql`
      query($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            id
            title
            createdAt
            description
            productType
            publishedAt
            tags
            updatedAt
            vendor
            images(first: 250) {
              edges {
                node {
                  src
                  id
                  altText
                }
              }
            }
            variants(first: 250) {
              edges {
                node {
                  id
                  title
                  price {
                    amount
                  }
                  compareAtPrice {
                    amount
                  }
                  image {
                    src
                    id
                    altText
                  }
                }
              }
            }
          }
        }
      }
      `,
      variables: {
        id: _id
      }
    })
    .then(data => {
      const { id, title, description, productType, tags, publishedAt, updatedAt, vendor , createdAt, variants, images } = data.data.product;
      const t = {
              id,
              title,
              description,
              productType,
              publishedAt,
              updatedAt,
              tags,
              vendor,
              createdAt,
              variants: variants.edges.map(variantEdge => ({
                id: variantEdge.node.id,
                title: variantEdge.node.title,
                price: variantEdge.node.price.amount,
                compareAtPrice: variantEdge.node.compareAtPrice?.amount,
                image: variantEdge.node.image,

              })),
              images: images.edges.map(imageEdge => ({
                id: imageEdge.node.id,
                src: imageEdge.node.src,
                altText: imageEdge.node.altText
              })),
            };

      return t;


    });

}

getProductByIdExtrafiled(_id) {
    // JSON.stringify();
    const query = `{
        node(id: "` + _id + `") {
          ... on Product {
            metafields(identifiers: {namespace: "global", key: "field_name"}){
              key
                  namespace
                  value
                  description
            }
            variants(first: 250) {
              edges {
                node {
                  compareAtPrice {
                    amount
                  }
                }
              }
            }
          }
        }
    }`;

    return $.ajax({
        method: 'POST',
        url: environment.urljson,
        contentType: 'application/graphql',
        headers: {
          'Content-Type': 'application/graphql',
          'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
        },
        data: query
  });

}

getProductsTopSix(): Promise<any> {
return this.apolloClient.query({
  query: gql`
  {
    products(first: 6) {
      edges {
        node {
          id
          title
          tags
          images(first: 250) {
            edges {
              node {
                src
                id
                altText
              }
            }
          }
          variants(first: 250) {
            edges {
              node {
                id
                title
                price {
                  amount
                }
                image {
                  src
                  id
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
  `
})
.then(data => {
  const transformedData = {
    data: {
      collections: data.data.products.edges.map(edge => {
        const { id, title, tags,  variants, images } = edge.node;
        return {
          id,
          title,
          tags,
          variants: variants.edges.map(variantsEdge => ({
            id: variantsEdge.node.id,
            title: variantsEdge.node.title,
            price: variantsEdge.node.price.amount,
            images: variantsEdge.images
          })),
          images: images.edges.map(imagesEdge => ({
            id: imagesEdge.node.id,
            src: imagesEdge.node.src,
            altText: imagesEdge.node.altText,
          }))
        };
      })
    }
  };
  return transformedData.data.collections;

});
}

getPages(): Promise<any> {
    return $.ajax({
      method: 'POST',
      url: environment.urljson,
      contentType: 'application/json',
      headers: {
        'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
      },
      data: JSON.stringify({
        query: `{
          pages(first:250) {
            edges {
              node {
                body
                title
              }
            }
          }
        }
        `
      })
});


  }

getProductsFive(): Promise<any> {
return this.apolloClient.query({
  query: gql`
  {
    products(first: 5) {
      edges {
        node {
          id
          title
          tags
          images(first: 250) {
            edges {
              node {
                src
                id
                altText
              }
            }
          }
          variants(first: 250) {
            edges {
              node {
                id
                title
                price {
                  amount
                }
                image {
                  src
                  id
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
  `
})
.then(data => {
  const transformedData = {
    data: {
      collections: data.data.products.edges.map(edge => {
        const { id, title, tags,  variants, images } = edge.node;
        return {
          id,
          title,
          tags,
          variants: variants.edges.map(variantsEdge => ({
            id: variantsEdge.node.id,
            title: variantsEdge.node.title,
            price: variantsEdge.node.price.amount,
            images: variantsEdge.node.images
          })),
          images: images.edges.map(imagesEdge => ({
            id: imagesEdge.node.id,
            src: imagesEdge.node.src,
            altText: imagesEdge.node.altText,
          }))
        };
      })
    }
  };
  return transformedData.data.collections;

});

  }

getProductsSearch(_prefix): Promise<any> {

    return this.apolloClient.query({
      query: gql`
        query GetProducts($first: Int!, $query: String!) {
        products(first: $first, query: $query) {
          edges {
            node {
              id
              title
              tags
              images(first: 250) {
                edges {
                  node {
                    src
                    id
                    altText
                  }
                }
              }
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    price {
                      amount
                    }
                    image {
                      src
                      id
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        first: 10,
        query: `title:${_prefix}`
      }
    })
    .then(data => {
      const transformedData = {
        data: {
          collections: data.data.products.edges.map(edge => {
            const { id, title, tags, variants, images } = edge.node;
            return {
              id,
              title,
              tags,
              variants: variants.edges.map(variantsEdge => ({
                id: variantsEdge.node.id,
                title: variantsEdge.node.title,
                price: variantsEdge.node.price.amount,
                image: {
                  id: variantsEdge.node.image.id,
                  src: variantsEdge.node.image.src,
                  altText: variantsEdge.node.image.altText,
                }
              })),
              images: images.edges.map(imagesEdge => ({
                id: imagesEdge.node.id,
                src: imagesEdge.node.src,
                altText: imagesEdge.node.altText,
              }))
            };
          })
        }
      };
      return transformedData.data.collections;
    });
  }

getCollection(): Promise<Collection[]> {
    return this.apolloClient.query({
      query: gql`
        query GetCollections {
            collections(first: 250) {
              edges {
                node {
                  id
                  title
                  description
                  descriptionHtml
                  products(first: 250) {
                    edges {
                      node {
                        id
                        title
                        tags
                        collections(first: 250) {
                          edges {
                            node {
                              id
                              title
                            }
                          }
                        }
                      }
                    }
                  }
                  image {
                    src
                    id
                    altText
                  }
                }
              }
            }
        }
      `
    })
    .then(data => {
      const transformedData = {
        data: {
          collections: data.data.collections.edges.map(edge => {
            const { id, title, description, descriptionHtml, products, image } = edge.node;
            return {
              id,
              title,
              description,
              descriptionHtml,
              products: products.edges.map(productEdge => ({
                id: productEdge.node.id,
                title: productEdge.node.title,
                tags: productEdge.node.tags,
                collections: productEdge.node.collections.edges.map(collectionEdge => ({
                  id: collectionEdge.node.id,
                  title: collectionEdge.node.title
                }))
              })),
              image
            };
          })
        }
      };
      console.log(transformedData);
      return transformedData.data.collections;
    });

  }

getProductsInCollection(collectionId): Promise<Collection> {

   return this.apolloClient.query({
      query: gql`
        query GetCollectionsById($id: ID!) {
            collection(id: $id) {
                  id
                  title
                  description
                  descriptionHtml
                  products(first: 250) {
                    edges {
                      node {
                        id
                        title
                        tags
                        variants(first: 250) {
                          edges {
                            node {
                              id
                              title
                              price {
                                amount
                              }
                              image {
                                src
                                id
                                altText
                              }
                            }
                          }
                        }
                        images(first: 250) {
                          edges {
                            node {
                              src
                              id
                              altText
                            }
                          }
                        }
                      }
                    }
                  }
                  image {
                    src
                    id
                    altText
                  }
                }

        }
      `,
      variables: {
        id: collectionId
      }
    })
    .then(data => {

      const { id, title, description, descriptionHtml, products, image } = data.data.collection;
      const t = {
              id,
              title,
              description,
              descriptionHtml,
              products: products.edges.map(productEdge => ({
                id: productEdge.node.id,
                title: productEdge.node.title,
                tags: productEdge.node.tags,
                variants: productEdge.node.variants.edges.map(variantsEdge => ({
                  id: variantsEdge.node.id,
                  title: variantsEdge.node.title,
                  price: variantsEdge.node.price.amount,
                  image: variantsEdge.node.image,
                })),
                images: productEdge.node.images.edges.map(imagesEdge => ({
                  id: imagesEdge.node.id,
                  src: imagesEdge.node.src,
                  altText: imagesEdge.node.altText,
                }))
              })),
              image
            };

      console.log(t);
      return t;

    });

  }

getProductsInCollectionByQuery(collectionId) {
    const query = `{
            node(id: "` + collectionId + `") {
              ... on Collection {
                id
                title
                description
                descriptionHtml
                products(first: 250) {
                  edges {
                    node {
                      title
                      id
                      tags
                      variants(first: 250) {
                        edges {
                          node {
                            compareAtPrice {
                              amount
                            }
                            price {
                              amount
                            }
                          }
                        }
                      }
                      images(first: 250) {
                        edges {
                          node {
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          `;

    return $.ajax({
          method: 'POST',
          url: environment.urljson,
          contentType: 'application/graphql',
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
          },
          data: query
      });
  }
// Login
customerAccessTokenCreate(_email, _password): Promise<any> {

    const input = this.client.variable('input', 'CustomerAccessTokenCreateInput!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('customerAccessTokenCreate', { args: { input } }, (customerAccessTokenCreate) => {
        customerAccessTokenCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        customerAccessTokenCreate.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt');
        });

      });
    });
    return this.client.send(mutation, { input: { email: _email, password : _password } });
  }

  // New User Create
customerCreate(_firstName, _lastName, _email, _password): Promise<any> {
    const input = this.client.variable('input', 'CustomerCreateInput!');
    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('customerCreate', { args: { input } }, (customerCreate) => {
        customerCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        customerCreate.add('customer', (customer) => {
          customer.add('id');
        });
      });
    });
    return this.client.send(mutation, { input: {firstName: _firstName, lastName: _lastName , email: _email, password : _password} });
  }

  // Update User
customerUpdate(_token, _firstName, _lastName, _phone, _Subscribe): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const customer = this.client.variable('customer', 'CustomerUpdateInput!');

    const mutation = this.client.mutation('myMutation', [customerAccessToken, customer], (root) => {
      root.add('customerUpdate', { args: { customerAccessToken, customer } }, (customerUpdate) => {
        customerUpdate.add('customer', (customer) => {
          customer.add('id');
        }),
        customerUpdate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
          userErrors.add('field');
        });
      });
    });

    return this.client.send(mutation, { customerAccessToken: _token, customer: {firstName: _firstName, lastName: _lastName , phone: _phone} });
  }

  // Forget Password
customerRecover(_email): Promise<any> {
    const email = this.client.variable('email', 'String!');
    const mutation = this.client.mutation('myMutation', [email], (root) => {
      root.add('customerRecover', { args: { email } }, (customerRecover) => {
        customerRecover.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
      });
    });
    return this.client.send(mutation, { email: _email });
  }

  // Reset Password
customerReset(_id, _resetToken, _password): Promise<any> {
    const id = this.client.variable('id', 'ID!');
    const input = this.client.variable('input', 'CustomerResetInput!');

    const mutation = this.client.mutation('myMutation', [id, input], (root) => {
      root.add('customerReset', { args: { id, input } }, (customerReset) => {
        customerReset.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        customerReset.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt');
        });
        customerReset.add('customer', (customer) => {
          customer.add('id');
        });
      });
    });
    return this.client.send(mutation, { id: _id, input: {resetToken: _resetToken, password: _password} });
  }

  // Reset Password By Url
  customerResetByUrl(_url, _password): Promise<any> {
    const resetUrl = this.client.variable('resetUrl', 'URL!');
    const password = this.client.variable('password', 'String!');

    const mutation = this.client.mutation('myMutation', [resetUrl, password], (root) => {
      root.add('customerResetByUrl', { args: { resetUrl, password } }, (customerReset) => {
        customerReset.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        customerReset.add('customerAccessToken', (access) => {
          access.add('accessToken'),
          access.add('expiresAt');
        });
        customerReset.add('customer', (customer) => {
          customer.add('id');
        });
      });
    });
    return this.client.send(mutation, { resetUrl: _url, password: _password });
  }

  fetchOrders(_token): Promise<any> {

  return this.apolloClient.query({
      query: gql`
      query($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          id
          orders(first: 250) {
            edges {
              node {
                id
                orderNumber
                customerUrl
                currencyCode
                processedAt
                totalPrice {
                  amount
                }
                totalRefunded {
                  amount
                }
                totalShippingPrice {
                  amount
                }
                totalTax {
                  amount
                }
              }
            }
          }
        }
      }
      `,
      variables: {
        customerAccessToken: _token
      }
    })
    .then(data => {

      const { id, orders } = data.data.customer;
      const t = {
              id,
              orders: orders.edges.map(ordersEdge => ({
                id: ordersEdge.node.id,
                currencyCode: ordersEdge.node.currencyCode,
                customerUrl: ordersEdge.node.customerUrl,
                orderNumber: ordersEdge.node.orderNumber,
                processedAt: ordersEdge.node.processedAt,
                totalPrice: ordersEdge.node.totalPrice.amount,
                totalRefunded: ordersEdge.node.totalRefunded.amount,
                totalShippingPrice: ordersEdge.node.totalShippingPrice.amount,
                totalTax: ordersEdge.node.totalTax.amount,
              }))
            };
      return t.orders;
    });
}

getOrderDetail(id) {
    const query = `{
            node(id: "` + id + `") {
              ... on Order {
                id
                email
                name
                orderNumber
                phone
                shippingAddress {
                  address1
                  address2
                  city
                  country
                  company
                  countryCode
                  firstName
                  id
                  lastName
                  name
                  phone
                  zip
                  province
                }
                lineItems(first:250) {
                  edges {
                    node {
                      title
                      quantity
                      customAttributes {
                        key
                        value
                      }
                      variant {
                        compareAtPrice {
                          amount
                        }
                        price {
                          amount
                        }
                        image {
                          originalSrc
                          id
                          src
                        }
                        product {
                          id
                        }
                      }
                    }
                  }
                }
                subtotalPrice {
                  amount
                }
                totalPrice {
                  amount
                }
                totalRefunded {
                  amount
                }
                totalShippingPrice {
                  amount
                }
                totalTax {
                  amount
                }
                statusUrl
                customerUrl
                processedAt
              }
            }
          }
          `;

    return $.ajax({
          method: 'POST',
          url: environment.urljson,
          contentType: 'application/graphql',
          headers: {
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
          },
          data: query
      });
  }
  fetchaddress(_token): Promise<any> {

    const query = this.client.query((root) => {
        root.add('customer', { args: { customerAccessToken: _token } }, (customer) => {
          customer.add('id');
          customer.addConnection('addresses', {args: {first: 250}}, (address) => {
              address.add('address1');
              address.add('address2');
              address.add('city');
              address.add('company');
              address.add('country');
              address.add('province');
              address.add('countryCode');
              address.add('formatted');
              address.add('firstName');
              address.add('id');
              address.add('lastName');
              address.add('name');
              address.add('phone');
              address.add('zip');
        });
        });
    });

    return this.client.send(query).then(({model, data}) => {
      return model.customer.addresses;
    });

  }

fetchcustomer(_token): Promise<any> {

    const query = this.client.query((root) => {
        root.add('customer', { args: { customerAccessToken: _token } }, (customer) => {
          customer.add('id');
          customer.add('firstName');
          customer.add('lastName');
          customer.add('displayName');
          customer.add('email');
          customer.add('phone');
        });
    });

    return this.client.send(query).then(({model, data}) => {
      return model.customer;
    });

  }

customerAddressCreate(_token, _address: Address): Promise<any> {
     const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
     const address = this.client.variable('address', 'MailingAddressInput!');

     const mutation = this.client.mutation('myMutation', [customerAccessToken, address], (root) => {
      root.add('customerAddressCreate', { args: { customerAccessToken, address } }, (customerAddressCreate) => {
        customerAddressCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        customerAddressCreate.add('customerAddress', (customerAddress) => {
          customerAddress.add('id');
        });
      });
    });

     const add = {
      address1: _address.address1,
      address2: _address.address2,
      city: _address.city,
      province: _address.province,
      company : _address.company,
      country : _address.country,
      firstName : _address.firstName,
      lastName : _address.lastName,
      phone : _address.phone,
      zip : _address.zip
    };
     return this.client.send(mutation, {customerAccessToken: _token, address: add });
  }

customerAddressEdit(_token, _id, _address: Address): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const id = this.client.variable('id', 'ID!');
    const address = this.client.variable('address', 'MailingAddressInput!');

    const mutation = this.client.mutation('myMutation', [customerAccessToken, id, address], (root) => {
     root.add('customerAddressUpdate', { args: { customerAccessToken, id, address } }, (customerAddressUpdate) => {
      customerAddressUpdate.add('userErrors', (userErrors) => {
         userErrors.add('message'),
           userErrors.add('field');
       });
      customerAddressUpdate.add('customerAddress', (customerAddress) => {
         customerAddress.add('id');
       });
     });
   });

    const add = {
     address1: _address.address1,
     address2: _address.address2,
     city: _address.city,
     province: _address.province,
     company : _address.company,
     country : _address.country,
     firstName : _address.firstName,
     lastName : _address.lastName,
     phone : _address.phone,
     zip : _address.zip
   };
    return this.client.send(mutation, {customerAccessToken: _token, id: _id, address: add });
 }

customerAddressRemove(_token, _id): Promise<any> {
    const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
    const id = this.client.variable('id', 'ID!');

    const mutation = this.client.mutation('myMutation', [id, customerAccessToken], (root) => {
     root.add('customerAddressDelete', { args: { id, customerAccessToken } }, (customerAddressDelete) => {
      customerAddressDelete.add('deletedCustomerAddressId'),
      customerAddressDelete.add('userErrors', (userErrors) => {
         userErrors.add('message'),
           userErrors.add('field');
       });

     });
   });
    return this.client.send(mutation, {customerAccessToken: _token, id: _id });
 }

createCheckout(_lineItems): Promise<any> {

    const _lineItemsForCheckout = _lineItems.map(item => ({ variantId: item.variantId, quantity: item.quantity }));

    const input = this.client.variable('input', 'CheckoutCreateInput!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCreate', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        checkoutCreate.add('checkout', (checkout) => {
            checkout.add('id'),
            checkout.add('webUrl'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {

              lineItems.add('variant', (variant) => {
                variant.add('title');
              }),
                lineItems.add('quantity');
            }
            );
        });
      });
    });
    return this.client.send(mutation, { input: { lineItems: _lineItemsForCheckout } });
  }

  checkoutCompleteFree(_checkoutId): Promise<any> {
    const input = this.client.variable('checkoutId', 'ID!');

    const mutation = this.client.mutation('myMutation', [input], (root) => {
      root.add('checkoutCompleteFree', { args: { input } }, (checkoutCreate) => {
        checkoutCreate.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });
        checkoutCreate.add('checkout', (checkout) => {
            checkout.add('id');
        });
      });
    });
    return this.client.send(mutation, {checkoutId: _checkoutId});
  }

fetchCheckout(_checkoutid): Promise<any> {
   return this.apolloClient.query({
    query: gql`
    query GetCheckoutDetails($id: ID!) {
      node(id: $id) {
        id
        ... on Checkout {
          webUrl
          email
          subtotalPrice {
            amount
          }
          totalTax {
            amount
          }
          totalPrice {
            amount
          }
          shippingAddress {
            address1
            address2
            city
            company
            country
            firstName
            lastName
            phone
            zip
            province
          }
          lineItems(first: 250) {
            edges {
              node {
                title
                variant {
                  title
                  image {
                    src
                  }
                  price {
                    amount
                  }
                }
                quantity
              }
            }
          }
        }
      }
    }
    `,
    variables: {
      id: _checkoutid
    }
  })
  .then(data => {
    const { id, email, shippingAddress, webUrl, subtotalPrice, totalTax, totalPrice, lineItems } = data.data.node;
    const t = {
          id,
          email,
          shippingAddress,
          webUrl,
          subtotalPrice,
          totalTax,
          totalPrice,
          lineItems: lineItems.edges.map(lineItemsEdge => ({
            title: lineItemsEdge.node.title,
            quantity: lineItemsEdge.node.quantity,
            variant: lineItemsEdge.node.variant
          }))
        };

    return t;
  });

  }

  checkoutDiscountCodeApply(_discountCode, _checkoutId): Promise<any> {
    const discountCode = this.client.variable('discountCode', 'String!');
    const checkoutId = this.client.variable('checkoutId', 'ID!');

    const mutation = this.client.mutation('myMutation', [discountCode, checkoutId], (root) => {
      root.add('checkoutDiscountCodeApply', { args: { discountCode, checkoutId } }, (discountCodeApply) => {
        discountCodeApply.add('userErrors', (userErrors) => {
            userErrors.add('message'),
            userErrors.add('field');
        });
        discountCodeApply.add('checkout', (checkout) => {
          checkout.add('id');
        });
      });
    });
    return this.client.send(mutation, {discountCode: _discountCode, checkoutId: _checkoutId });
 }

checkoutDiscountCodeApplyQuery(_discountCode, _checkoutId): Promise<any> {
  const query = ` mutation {
              checkoutDiscountCodeApply(discountCode: "` + _discountCode + `", checkoutId: "` + _checkoutId + `") {
                checkout {
                  id
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `;

  return $.ajax({
            method: 'POST',
            url: environment.urljson,
            contentType: 'application/graphql',
            headers: {
              'Content-Type': 'application/graphql',
              'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
            },
            data: query
          });
}

 checkoutDiscountCodeRemove(_checkoutId): Promise<any> {
 const checkoutId = this.client.variable('checkoutId', 'ID!');
 const mutation = this.client.mutation('myMutation', [checkoutId], (root) => {
   root.add('checkoutDiscountCodeRemove', { args: { checkoutId } }, (checkoutDiscountCodeRemove) => {
    checkoutDiscountCodeRemove.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field');
     });
    checkoutDiscountCodeRemove.add('checkout', (checkout) => {
      checkout.add('id');
     });
   });
 });
 return this.client.send(mutation, {checkoutId: _checkoutId });
 }

 checkoutDiscountCodeRemoveQuery(_checkoutId): Promise<any> {
  const query = ` mutation {
    checkoutDiscountCodeRemove(checkoutId: "` + _checkoutId + `") {
                checkout {
                  id
                }
                checkoutUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `;

  return $.ajax({
            method: 'POST',
            url: environment.urljson,
            contentType: 'application/graphql',
            headers: {
              'Content-Type': 'application/graphql',
              'X-Shopify-Storefront-Access-Token': environment.shopifyStorefrontAccessToken
            },
            data: query
          });
}

 checkoutEmailUpdate(_email, _checkoutId): Promise<any> {
  return this.apolloClient.mutate({
  mutation: gql`
  mutation myMutation($email: String!, $checkoutId: ID!) {
    checkoutEmailUpdateV2(email: $email, checkoutId: $checkoutId) {
      userErrors {
        message
        field
      }
      checkout {
        id
      }
    }
  }
  `,
  variables: {
    email: _email,
    checkoutId: _checkoutId
  }
})
.then(data => {
 return data.data;
});
}

checkoutCustomerAssociate(_customerAccessToken, _checkoutId): Promise<any> {
  const customerAccessToken = this.client.variable('customerAccessToken', 'String!');
  const checkoutId = this.client.variable('checkoutId', 'ID!');

  const mutation = this.client.mutation('myMutation', [customerAccessToken, checkoutId], (root) => {
   root.add('checkoutCustomerAssociate', { args: { customerAccessToken, checkoutId } }, (checkoutEmailUpdate) => {
    checkoutEmailUpdate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field');
     });
    checkoutEmailUpdate.add('checkout', (checkout) => {
      checkout.add('id');
     });
    checkoutEmailUpdate.add('customer', (customer) => {
      customer.add('id');
     });
   });
 });
  return this.client.send(mutation, {customerAccessToken: _customerAccessToken, checkoutId: _checkoutId });
}

checkoutCustomerDisassociate(_checkoutId): Promise<any> {
  const checkoutId = this.client.variable('checkoutId', 'ID!');

  const mutation = this.client.mutation('myMutation', [checkoutId], (root) => {
   root.add('checkoutCustomerDisassociate', { args: { checkoutId } }, (checkoutCustomerDisassociate) => {
    checkoutCustomerDisassociate.add('userErrors', (userErrors) => {
       userErrors.add('message'),
         userErrors.add('field');
     });
    checkoutCustomerDisassociate.add('checkout', (checkout) => {
      checkout.add('id');
     });
   });
 });
  return this.client.send(mutation, {checkoutId: _checkoutId });
}

checkoutShippingAddressUpdate(_checkoutId, _shippingAddress: Address): Promise<any> {
  const add = {
   address1: _shippingAddress.address1,
   address2: _shippingAddress.address2,
   city: _shippingAddress.city,
   province: _shippingAddress.province,
   company : _shippingAddress.company,
   country : _shippingAddress.country,
   firstName : _shippingAddress.firstName,
   lastName : _shippingAddress.lastName,
   phone : _shippingAddress.phone,
   zip : _shippingAddress.zip
 };
  return this.apolloClient.mutate({
  mutation: gql`
  mutation myMutation($checkoutId: ID!, $shippingAddress: MailingAddressInput!) {
    checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
      userErrors {
        message
        field
      }
      checkout {
        id
      }
    }
  }
  `,
  variables: {
    checkoutId: _checkoutId,
    shippingAddress: add
  }
})
.then(data => {
 return data.data;
});


}

addVariantsToCheckout(_checkoutid, _lineItems): Promise<any> {
    const checkoutId = this.client.variable('checkoutId', 'ID!');
    const _lineItemsForCheckout = _lineItems.map(item => ({ id: item.id, variantId: item.variantId, quantity: item.quantity }));
    const lineItems = this.client.variable('lineItems', '[CheckoutLineItemInput!]!');

    const mutation = this.client.mutation('myMutation', [checkoutId, lineItems], (root) => {
      root.add('checkoutLineItemsAdd', { args: { checkoutId, lineItems } }, (checkoutLineItemsAdd) => {

        checkoutLineItemsAdd.add('userErrors', (userErrors) => {
          userErrors.add('message'),
            userErrors.add('field');
        });

        checkoutLineItemsAdd.add('checkout', (checkout) => {
          checkout.add('webUrl'),
            checkout.add('subtotalPrice'),
            checkout.add('totalTax'),
            checkout.add('totalPrice'),
            checkout.addConnection('lineItems', { args: { first: 250 } }, (lineItems) => {
              lineItems.add('variant', (variant) => {
                variant.add('title'),
                  variant.add('image', (image) => image.add('src')),
                  variant.add('price');
              }),
                lineItems.add('quantity');
            });
        });
      });
    });

    return this.client.send(mutation, { checkoutId: _checkoutid, lineItems: _lineItemsForCheckout });

  }

  removeLineItem(_checkoutid, _lineItemId): Promise<any> {
   return this.apolloClient.mutate({
      mutation: gql`
      mutation myMutation($checkoutId: ID!, $lineItemIds: [ID!]!) {
        checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
          userErrors {
            message
            field
          }
          checkout {
            webUrl
            subtotalPrice {
              amount
            }
            totalTax {
              amount
            }
            totalPrice {
              amount
            }
            lineItems(first: 250) {
              edges {
                node {
                  variant {
                    title
                    image {
                      src
                    }
                    price {
                      amount
                    }
                  }
                  quantity
                }
              }
            }
          }
        }
      }
      `,
      variables: {
        checkoutId: _checkoutid,
        lineItemIds: [_lineItemId]
      }
    })
    .then(data => {
     return data.data;

    });
  }

  updateLineItem(_checkoutid, _lineItems): Promise<any> {

    // Prepare the variables
    const _lineItemsForCheckoutQuery = _lineItems.map(item => ({
      id: item.id,
      variantId: item.variantId,
      quantity: item.quantity
    }));


    return this.apolloClient.mutate({
      mutation: gql`
      mutation myMutation($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
        checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
          userErrors {
            message
            field
          }
          checkout {
            webUrl
            subtotalPrice {
              amount
            }
            totalTax {
              amount
            }
            totalPrice {
              amount
            }
            lineItems(first: 250) {
              edges {
                node {
                  variant {
                    title
                    image {
                      src
                    }
                    price {
                      amount
                    }
                  }
                  quantity
                }
              }
            }
          }
        }
      }
      `,
      variables: {
        checkoutId: _checkoutid,
        lineItems: _lineItemsForCheckoutQuery
      }
    })
    .then(data => {
     return data.data;
    });


  }

  SendMail(obj: any): Observable<any> {
    const mailgunApiKey = window.btoa('api:' + environment.MailgunAPIKEY);
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization : 'Basic ' + mailgunApiKey
    });
    const options = {
      headers: httpHeaders
    };
    const url = 'https://api.mailgun.net/v3/' + environment.MailgunDomain + '/messages';
    return this.http.post(url, obj, options);
  }

}


