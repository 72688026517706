import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/interceptor';
import { HttpErrorinterceptor } from './interceptors/http-errorinterceptor';
import { LoaderInterceptor } from './interceptors/loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { MaterialElevationDirective } from './directive/material-elevation.directive';
import { OnlynumberDirective } from './directive/onlynumber.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import {
  NgbModule,
  NgbPaginationModule,
  NgbTypeaheadModule
  } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { AccountComponent } from './pages/account/account.component';
import { SettingComponent } from './pages/setting/setting.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfilesComponent } from './pages/profiles/profiles.component';
import { LegalComponent } from './pages/legal/legal.component';
import { CartComponent } from './pages/cart/cart.component';
import { OrderComponent } from './pages/order/order.component';
import {ServiceModule} from "./service/service.model";
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderWithloginComponent } from './header-withlogin/header-withlogin.component';
import { NewsLetterComponent } from './news-letter/news-letter.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { BlogComponent } from './pages/blog/blog.component';
import { GraphQLModule } from './graphql.module';
// import { GraphQLModule } from './graphql.module';


@NgModule({
  declarations: [
    AppComponent,
    OnlynumberDirective,
    ConfirmationDialogComponent,
    MaterialElevationDirective,
    HomeComponent,
    AccountComponent,
    SettingComponent,
    ProductComponent,
    ProfilesComponent,
    LegalComponent,
    CartComponent,
    OrderComponent,
    FooterComponent,
    HeaderComponent,
    HeaderWithloginComponent,
    NewsLetterComponent,
    AboutComponent,
    ContactusComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClickOutsideModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    GraphQLModule,
    // GraphQLModule
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [ServiceModule,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorinterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
