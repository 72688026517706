<div class="mainWrapper">
    
    <div *ngIf="!login">
        <app-header></app-header>
    </div>
    <div *ngIf="login">
        <app-header-withlogin></app-header-withlogin>
    </div>
    <div class="middle">
        <router-outlet></router-outlet>
        <app-news-letter></app-news-letter>
    </div>
    <app-footer></app-footer>
</div>